import {
  // Mumbai,
  Polygon
} from '@usedapp/core';

import polygon_currency from '../images/currency/polygon-currency.png'
import arbitrum_currency from '../images/currency/arbitrum-currency.png'

export const MAIN_URL = "https://app-games.jellygames.io";

// export const GAMES_URL = "http://localhost:3000";
export const GAMES_URL = "https://games.jellygames.io";

export const PARTNER_URL = "https://jellybet.io";

export const COIN_FLIP_GAME = "coinflip";

export const MEGA_DICE_GAME = "megadice";

export const LIMBO_GAME = "limbo";

export const WHEEL_GAME = "luckyWheel";

export const FREE_WHEEL_GAME = "freeWheel";

// export const POLYGON_NETWORK = "polygonMumbai";
export const POLYGON_NETWORK = "polygon";

export const USDT_CURRENCY = "USDT";

export const LIVE_TABLE = "live";

export const MY_TABLE = "my-games";

// export const DEFAULT_CHAIN_ID = Mumbai.chainId;
export const DEFAULT_CHAIN_ID = Polygon.chainId;

export const BALANCE_DIVIDER = Math.pow(10, 18);

export const IERC20_METADATA_ABI = [
  // funcs
  "function decimals() external view returns (uint8)",
]

export const ESC_KEY = "Escape";

export const METAMASK_CONNECTOR = "injected";

export const WALLET_CONNECTOR = "walletConnect";

export const COINBASE_CONNECTOR = "coinbaseWallet";

export const DEMO_DECIMALS = 18;

export const DEFAULT_DECIMALS = 6;

export const NETWORKS = {
  '137': {
    name: 'Polygon',
    logo: polygon_currency,
    api_name: 'polygon',
    scan_domain: 'polygonscan.com',
    token_address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f"
  },
  '42161': {
    name: 'Arbitrum',
    logo: arbitrum_currency,
    api_name: 'arbitrumOne',
    scan_domain: 'arbiscan.io',
    token_address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9"
  },
}

export const DEFAULT_NETWORK = NETWORKS[DEFAULT_CHAIN_ID.toString()]
export const DEFAULT_USDT_ADDRESS = NETWORKS[DEFAULT_CHAIN_ID.toString()].token_address;

export const NETWORKS_ARRAY = Object.entries(NETWORKS).map(([chain_id, item]) => ({ ...item, chain_id }));