/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

// import GamesTable from "../GamesTable/GamesTable";

import {
  GAMES_URL,
  // LIMBO_GAME,
  PARTNER_URL
} from "../../assets/utilis/constants";
import './Charger.css'

function Charger({
  chainId,
  // gameHistory,
  // setGameHistory,
  // tokenDecimals,
  setFrameLoaded
}) {

  const [gameUrl, setGameUrl] = useState(undefined);
  
  useEffect(() => {
    setFrameLoaded(false);
    let iframe = document.getElementsByClassName("app__iframe");
    if (iframe.length !== 0) {
      iframe[0].addEventListener('load', () => {
        // Do something when the iframe is loaded
        setTimeout(() => {
          setFrameLoaded(true)
        }, 1500);
      });
    }
  }, [])

  useEffect(() => {
    setGameUrl(
      GAMES_URL + '/charger?' + new URLSearchParams({
        // '--bg-color': "#14182D",
        // '--text-color': "#FFF",
        // '--contrast-color': '#8F00FF',
        // '--contrast-color-opacity': 'rgba(143, 0, 255, 0.16)',
        // '--text-on-contrast-color': '#FFFFFF',

        // '--container-bg-color': 'rgba(24, 28, 50, 0.90)',
        // '--container-secondary-bg-color': 'rgba(24, 28, 50, 0.70)',
        // '--container-contrast-bg-color': '#2A2C3C',
        // '--number-box-border-color': '#123123',
        // '--number-box-bg-color': '#dd11ff',

        is_demo: false, //Set game type - true=DEMO false=ONCHAIN
        show_demo_switch: true, //Turn on/off demo swith block in header
        chain_id: chainId,
        partner_referral_address: '', //your blockhain address
        partner_url: PARTNER_URL, //your website url address
        lang: 'en', //Only EN lang is availible for now
        return_url: window.location.href,
        is_external_account_connection: true
      })
    )
  }, [])

  return (
    <section
      className={`charger`}
    >
      <iframe
         src={gameUrl}
         className='app__iframe'
         title='Paraliq || Lucky Wheel'
         width={'100%'}
         height="800px"
         frameBorder='none'
      ></iframe>
      {/* <div className="charger__table-block">
        <GamesTable
          gameType={LIMBO_GAME}
          games={gameHistory}
          setGames={setGameHistory}
          {...{
            tokenDecimals
          }}
        />
      </div> */}
    </section>
  );
}

export default Charger;